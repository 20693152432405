import { TextInput, Select, Button, Radio, MultiSelect } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BeatLoader } from "react-spinners";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import colors from "../../utilities/design";
import {
  existsAndLength,
  getValueForInput,
  loadingStates,
  validateEmail,
  validateMobile,
} from "../../utilities/utilities";
import styles from "../ServiceProviderForm/ServiceProviderForm.module.css";

import { createServiceProvider } from "../../utilities/apis/serviceProvider";
import { selectUserData } from "../../redux/selectors";
import { createBusinessUnit } from "../../utilities/apis/serviceProvider";


function AddOrganisationForm({ formClose }) {
  const [OrgData, setOrgData] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    OrgName: "",
    businessunitName: "",
    errors: {},
  });
  const currentUser = useSelector(selectUserData) || {};

  const userDetails = useSelector(selectUserData);
  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setOrgData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const validate = () => {
    const keys = {};

    if (!existsAndLength(OrgData.businessunitName)) {
      keys.businessunitName = "Please check Business Unit Name.";
    }

    if (!existsAndLength(OrgData.businessunitName)) {
      keys.businessunitName = "Please check Business Unit Name.";
    }

    if (Object.keys(keys).length) {
      showNotification({
        color: "red",
        title: "Service Record",
        message: "Make sure all fields are filled properly.",
      });
      setOrgData((data) => ({
        ...data,
        errors: keys,
      }));
      return false;
    }
    return true;
  };

  const OrgHandler = async () => {
    if (validate()) {
      setOrgData((data) => ({
        ...data,
        loading: loadingStates.LOADING,
        errors: {},
      }));
      const OrgApiData = {
        orgId: currentUser.mapOrganization,
        businessunitName: OrgData.businessunitName,
      };

      console.log("---BC---", OrgApiData);

      const resp = await apiWrapWithErrorWithData(
        createBusinessUnit({
          OrgData: OrgApiData,
        })
      );
      // console.log(resp);
      if (resp?.success) {
        setOrgData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
        showNotification({
          color: "green",
          title: "Service Provider Record Created.",
          message: "Service Provider has been created.",
        });
        if (formClose) {
          formClose();
          //   window.location.reload()
        }
      } else {
        showNotification({
          color: "red",
          title: "Service Provider Record",
          message:
            resp?.message || "Service Provider Record could not be created.",
        });
        setOrgData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
      }
    }
  };


  return (
    <>
      <div className="flex justify-start m-5">

        <TextInput
          className="w-[100%]"
          placeholder="Enter Business Unit"
          label="Business Unit"
          value={OrgData.businessunitName}
          onChange={changeHandler("businessunitName")}
          error={OrgData.errors.businessunitName}
        />
      </div>


      <div className="flex justify-end mt-8">

        <Button
          onClick={OrgHandler}
          disabled={OrgData.loading === loadingStates.LOADING}
          style={{
            backgroundColor: "#46BDE1",
            borderRadius: "0.5rem",
            color: "#F5F5F5",
          }}
        >
          {OrgData.loading === loadingStates.LOADING && (
            <BeatLoader color={colors.primary} size={10} />
          )}
          {OrgData.loading === loadingStates.NO_ACTIVE_REQUEST && (
            <span> Save </span>
          )}
        </Button>
      </div>
      {/* // </div> */}
    </>
  );
}

export default AddOrganisationForm;
