import React, { useState, useEffect } from "react";
import "tailwindcss/tailwind.css";
import {
  Textarea,
  Select,
  TextInput,
  SegmentedControl,
  Checkbox,
  Modal,
  Button,
  Table,
  Pagination,
  ActionIcon,
} from "@mantine/core";
import { Search, Send } from "tabler-icons-react";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { formatDate } from "../../utilities/utilities";
import {
  fetchPartyDetailApi,
} from "../../utilities/apis/recovery";
import AgreementListModal from "../AgreementsManager/AgreementListModal";
import { getBussinessUnit } from "../../utilities/apis/serviceProvider";

function PartyDetailPage({ formState, setFormState, errors }) {
  const [registeredNameDropDown, setRegisteredNameDropDown] = useState([]);
  const [businessUnitDropDown, setBusinessUnitDropDown] = useState([]);
  const [agreementData, setAgreementData] = useState({
    isModalOpen: false,
    list: [],
    selectedAgreement: null,
  });
  const fetchBusinessDetails = async () => {
    try {
      const { data } = await getBussinessUnit();
      if (data.success) {
        setBusinessUnitDropDown(data.result);
      } else {
        console.error("Invalid business unit response format:", response);
      }
    } catch (error) {
      console.error("Error fetching business units:", error);
    }
  };

  const fetchPartyDetail = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      fetchPartyDetailApi({ name })
    );
    const names = res.map(({ registeredName: val }) => val);
    setRegisteredNameDropDown(names);
  };

  useEffect(() => {
    fetchPartyDetail();
    fetchBusinessDetails();
  }, []);
  return (
    <>
      {/* <Modal
        overflow="inside"
        opened={agreementData.isModalOpen}
        onClose={() =>
          setAgreementData({ ...agreementData, isModalOpen: false })
        }
        size="calc(70vw)"
        title="Agreement List"
      >
        <Table striped highlightOnHover={true} className="">
          <thead>
            <tr>
              <th>Reference no.</th>
              <th>Type</th>
              <th>Location</th>
              <th>Status</th>
              <th>Second Party</th>
              <th>Date</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {agreementData.list.map((agreement) => (
              <tr key={agreement.id}>
                <td>{agreement.id}</td>
                <td>{agreement.typeOfAgreement}</td>
                <td>{agreement.location}</td>
                <td>{agreement.status}</td>
                <td>{agreement.secondParty.name}</td>
                <td>{formatDate(agreement.createdAt)}</td>
                <td>
                  <ActionIcon
                    title="Select Agreement"
                    onClick={() => handleAgreementSelect(agreement)}
                  >
                    <Send color="green" />
                  </ActionIcon>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        </Modal> */}
      <AgreementListModal
        agreementData={agreementData}
        setAgreementData={setAgreementData}
        setFormState={setFormState}
        setRegisteredNameDropDown={setRegisteredNameDropDown}
      />
      <div className="grid grid-cols-3 gap-4">
        <Checkbox
          className="mt-9"
          checked={formState.isExistingAgreement}
          label="for existing agreement ?"
          onChange={() =>
            setFormState((prevState) => ({
              ...prevState,
              isExistingAgreement: !prevState.isExistingAgreement,
            }))
          }
        />
        {formState.isExistingAgreement === true && (
          <div className="flex">
            <TextInput
              label="connected agreement"
              placeholder="enter agreement number"
              type="number"
              value={agreementData.selectedAgreement}
              onChange={(event) => {
                // const value = event.currentTarget.value;
                // const commaSeparatedValues = value.split(",").map((v) => v.trim());
                // form.setFieldValue(
                //   "relationshipWith",
                //   event.currentTarget.value
                // );
              }}
              style={{
                width: "88%",
                display: "inline-block",
                marginRight: "2%",
              }}
            />
            <Button
              className="max-w-lg"
              color="#2196F3"
              onClick={() => setAgreementData({ ...agreementData, isModalOpen: true })}
              style={{
                width: "10%",
                display: "inline-block",
                marginBottom: "0",
                marginTop: "auto",
                padding: "0",
              }}
            >
              <Search size={20} strokeWidth={3} />
            </Button>
          </div>
        )}
        <Select
          required
          label="business unit"
          placeholder="select business unit"
          value={formState.businessUnit}
          data={businessUnitDropDown.map((unit) => ({
            value: unit.id,
            label: unit.name,
          }))}
          onChange={(value) =>
            setFormState((prevState) => ({
              ...prevState,
              businessUnit: value,
            }))
          }
          error={errors.businessUnit}
        />
        <TextInput
          label="business poc"
          placeholder="enter business poc"
          value={formState.businessPOC}
          onChange={(event) => {
            const newValue = event.currentTarget.value;
            setFormState((prevState) => ({
              ...prevState,
              businessPOC: newValue,
            }));
          }}
        />
      </div>
      <div className="flex flex-col">
        <br></br>
        <div className="flex justify-end" style={{ marginTop: "10px" }}>
          <SegmentedControl
            color="cyan"
            data={["entity", "individual"]}
            value={formState.type}
            onChange={(value) =>
              setFormState((prevState) => ({
                ...prevState,
                type: value,
              }))
            }
          />
        </div>
        <div className="grid grid-cols-3 gap-4">
          {formState.type === "entity" && (
            <>
              <Select
                required
                className="inputCustom w-full ml-0 leading-[1.55]"
                label="entity type"
                placeholder="select entity type"
                value={formState.entityType}
                data={[
                  { value: "Company", label: "Company" },
                  { value: "LLP", label: "LLP" },
                  { value: "Partnership Firm", label: "Partnership Firm" },
                  { value: "Sole Proprietary", label: "Sole Proprietary" },
                ]}
                onChange={(value) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    entityType: value,
                  }))
                }
                error={errors.entityType}
              />
              <Select
                required
                searchable
                creatable
                className="inputCustom w-full ml-0 m-0 p-0 leading-[1.55]"
                label="registered name"
                placeholder="enter registered name"
                value={formState.registeredName}
                getCreateLabel={(value) => `+ add ${value}`}
                data={[...registeredNameDropDown]}
                onCreate={(value) => {
                  setFormState((prevState) => ({
                    ...prevState,
                    registeredName: value,
                  }))
                  setRegisteredNameDropDown([...registeredNameDropDown, value]);
                }}
                onChange={(value) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    registeredName: value,
                  }))
                }
                error={errors.registeredName}
              />
              <TextInput
                required
                label="authorised representative name"
                placeholder="enter authorised representative name"
                value={formState.representativeName}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    representativeName: newValue,
                  }));
                }}
                error={errors.representativeName}
              />
              <TextInput
                required
                label="authorised representative email id"
                placeholder="enter authorised representative email id"
                value={formState.representativeEmail}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    representativeEmail: newValue,
                  }));
                }}
                error={errors.representativeEmail}
              />
              {/* <Select
                required
                label="Business Unit"
                placeholder="Select Business Unit"
                value={formState.businessUnit}
                data={businessUnitDropDown.map((unit) => ({
                  value: unit.id,
                  label: unit.name,
                }))}
                onChange={(value) =>
                  setFormState((prevState) => ({
                    ...prevState,
                    businessUnit: value,
                  }))
                }
                error={errors.businessUnit}
              />
              <TextInput
                label="Business POC"
                placeholder="Enter Business POC"
                value={formState.businessPOC}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    businessPOC: newValue,
                  }));
                }}
              /> */}
              <Textarea
                label="registered address"
                placeholder="enter registered address"
                value={formState.registeredAddress}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    registeredAddress: newValue,
                  }));
                }}
              />
              <Textarea
                label="corporate address"
                placeholder="enter corporate address"
                value={formState.corporateAddress}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    corporateAddress: newValue,
                  }));
                }}
              />
            </>
          )}
          {formState.type === "individual" && (
            <>
              <TextInput
                required
                label="individual name"
                placeholder="enter individual name"
                value={formState.individualName}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    individualName: newValue,
                  }));
                }}
                error={errors.individualName}
              />
              <TextInput
                required
                label="email id"
                placeholder="enter email id"
                value={formState.emailID}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    emailID: newValue,
                  }));
                }}
                error={errors.emailID}
              />
              <TextInput
                label="father's name"
                placeholder="enter father's name"
                value={formState.fathersName}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    fathersName: newValue,
                  }));
                }}
              />
              <Textarea
                label="residence address"
                placeholder="enter residence address"
                value={formState.residenceAddress}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    residenceAddress: newValue,
                  }));
                }}
              />
              <TextInput
                label="pan no."
                placeholder="enter pan no."
                value={formState.panNo}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    panNo: newValue,
                  }));
                }}
              />
              <TextInput
                label="phone no."
                type="number"
                placeholder="enter phone no."
                value={formState.phoneNo}
                onChange={(event) => {
                  const newValue = event.currentTarget.value;
                  setFormState((prevState) => ({
                    ...prevState,
                    phoneNo: newValue,
                  }));
                }}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PartyDetailPage;
