/** @format */

import React, { useEffect, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Modal,
  Pagination,
  SegmentedControl,
  Skeleton,
  Text,
} from "@mantine/core";
import { getValueForInput, loadingStates } from "../utilities/utilities";
import { apiWrapWithErrorWithData } from "../utilities/apiHelpers";
import {
  getSelfNotifications,
  setNotificationSeen,
} from "../utilities/apis/users";
import TaskDetailsUI from "../components/TaskNewUI/TaskDetailsUI";
import NotificationBox from "../components/CaseNotification/NotificationBox";
import DashBoardNotificationBox from "../components/CaseNotification/DashBoardNotificationBox";
import colors from "../utilities/design";
import { formatDate, formatTime } from "../utilities/utilities";

const Message = ({ text, seen, dateTime, moduleName, onBtnClick }) => (
  <div
    className={`p-2 rounded-lg mb-2 transition duration-300 ease-in-out transform hover:scale-100 ${seen
      ? "bg-gray-100 hover:bg-gray-200"
      : "bg-blue-50 border border-blue-300 hover:bg-blue-100"
      } w-full relative cursor-pointer`}
    onClick={onBtnClick || (() => { })}
  >
    <div className="flex justify-between items-center mb-1 gap-2">
      <div
        className={`text-sm text-gray-700 truncate hover:overflow-visible hover:whitespace-normal hover:truncate-none`}
      >
        {!seen && (
          <span className="absolute left-2 top-1/3 transform -translate-y-1/2 h-2 w-2 bg-blue-500 rounded-full"></span>
        )}
        <div className={`truncate hover:truncate-none hover:overflow-visible hover:whitespace-normal  ${seen ? "text-gray-500" : "text-blue-500 ml-4"}`}>{text}</div>
      </div>

      <div className="flex">
        <div className="flex flex-row mr-1 items-center w-[9rem]">
          <img
            src="/assets/images/calendar.svg"
            alt="calendar"
            className="w-3 h-3 opacity-55"
          />
          {/* <img src="/assets/images/clock.svg" alt="clock" className="w-3 h-3" /> */}
          <div className={`ml-1 text-xs text-gray-500`}>
            {formatDate(dateTime)}
            &nbsp;|&nbsp;
            {formatTime(dateTime)}
          </div>
        </div>
      </div>
    </div>

  </div>
);

const NotificationsPage = () => {
  const location = useLocation();

  const [configs, setConfigs] = useState({
    page: 1,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    notifications: null,
    taskToLoad: null,
    notificationsCount: null,
    filterOptions: location?.state?.filterOptions || "all",
  });

  const getNotifications = async () => {
    setConfigs({
      ...configs,
      loading: loadingStates.LOADING,
    });

    let newFilterOptions = {};

    if (configs.filterOptions.toLowerCase() === "all") {
      newFilterOptions = {};
    }

    let resp = await apiWrapWithErrorWithData(
      getSelfNotifications({
        page: configs.page || 1,
        filterOptions: newFilterOptions,
      })
    );
    if (resp?.success && resp?.result) {
      resp = resp.result;
      setConfigs({
        ...configs,
        notifications: resp.notifications,
        notificationsCount: resp.notificationsCount,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Notifications List",
      //   message: "Could not load notifications list.",
      // });
      setConfigs({
        ...configs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    }
  };
  useEffect(() => {
    getNotifications();
  }, [configs.filterOptions, configs.page]);

  const navigate = useNavigate();

  const notificationClickHandler = (notif) => () => {
    if (notif.task) {
      setConfigs({
        ...configs,
        taskToLoad: {
          parent: notif.parent,
          parentId: notif.parentId,
          parentTitle: notif.parentTitle,
          id: notif.task.id,
        },
      });
    }
    if (notif.case) {
      navigate(`/app/dispute-manager/cases/details/${notif.case.id}`);
    }
    if (notif.notice) {
      navigate(`/app/dispute-manager/legal-notices/details/${notif.notice.id}`);
    }
    if (notif.agreement) {
      navigate(`/app/agreements/details/${notif.agreement.id}`);
    }
    if (notif.recovery) {
      navigate(`/app/recovery/${notif.recovery.id}`);
    }
    apiWrapWithErrorWithData(
      setNotificationSeen({
        id: notif.id,
      })
    );
    return () => { };
  };

  const notificationButtonText = (notif) => {
    if (notif.task) {
      return "View Task";
    }
    if (notif.case) {
      return "View Case";
    }
    if (notif.agreement) {
      return "View Agreement";
    }
    if (notif.notice) {
      return "View Notice";
    }
    if (notif.recovery) {
      return "View Recovery";
    }
    return "View";
  };

  if (!configs.notifications || configs.loading === loadingStates.LOADING) {
    return (
      <div className="flex flex-col">
        <Skeleton height={95} radius="md" className="my-4" />
        <Skeleton height={95} radius="md" className="my-4" />
        <Skeleton height={95} radius="md" className="my-4" />
        <Skeleton height={95} radius="md" className="my-4" />
        <Skeleton height={95} radius="md" className="my-4" />
      </div>
    );
  }

  return (
    <>
      {configs.taskToLoad !== null && (
        <Modal
          overflow="inside"
          opened
          onClose={() => {
            setConfigs({
              ...configs,
              taskToLoad: null,
            });
          }}
          size="calc(80vw)"
        >
          <TaskDetailsUI
            parent={configs.taskToLoad.parent}
            parentId={configs.taskToLoad.parentId}
            taskId={configs.taskToLoad.id}
            title={configs.taskToLoad.parentTitle}
            onModalExit={() => {
              setConfigs({
                ...configs,
                taskToLoad: null,
              });
            }}
          />
        </Modal>
      )}
      {/* <div className="flex flex-col"> */}
      <div className="mx-5">
        <div className="flex justify-between items-center">
          <Text
            className="font-normal text-[20px] leading-[1.55] text-black my-5"
            style={{ color: "#46BDE1" }}
          >
            notifications
          </Text>
        </div>
        {configs.notifications.length === 0 && (
          <div
            className="flex justify-center items-center"
            style={{
              minHeight: "200px",
            }}
          >
            <Text>no notifications to show.</Text>
          </div>
        )}
        {configs.notifications.map((notif) => (
          <Message
            key={notif.id}
            text={notif.notificationText}
            seen={notif.seenAt}
            dateTime={notif.sentAt}
            moduleName={notif.module}
            onBtnClick={notificationClickHandler(notif)}
          />
        ))}
        {!!configs.notificationsCount && (
          <div className="flex justify-center mt-4">
            <Pagination
              onChange={(page) => {
                setConfigs({
                  ...configs,
                  page,
                });
              }}
              total={Math.ceil(configs.notificationsCount / 10)}
              page={configs.page}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NotificationsPage;
