import {
  TextInput, Select, Button, Radio, MultiSelect
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import { apiWrapWithErrorWithData } from '../../utilities/apiHelpers';
import colors from '../../utilities/design';
import {
  existsAndLength, getValueForInput, loadingStates, validateEmail, validateMobile,
} from '../../utilities/utilities';
import styles from './ServiceProviderForm.module.css';
import { createServiceProvider, registerNewUserApi, getBussinessUnit, getModuleList, serviceProviderUserRole, serviceProviderOrganisation } from '../../utilities/apis/serviceProvider';
import { selectUserData } from '../../redux/selectors';

function ServiceProviderForm({ formClose }) {
  const [serviceProviderData, setServiceProviderData] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    name: '',
    email: '',
    phone: '',
    password: '',
    designation: 'Lawyer',
    enabled: 'enabled',
    userRoleOptions: [],
    moduleOptions: [],
    organisationOptions: [],
    selectedOrganisation: null,
    selectedBusinessUnits: null,
    selectedModules: [],
    businessUnits: [],
    errors: {},
  });
  const userDetails = useSelector(selectUserData);
  const changeHandler = (name) => (input) => {
    const value = getValueForInput(input);
    setServiceProviderData((data) => ({
      ...data,
      [name]: value,
    }));
  };

  const fetchBusinessUnits = async () => {
    try {
      const { data } = await getBussinessUnit();

      if (data.success) {
        // console.log("Inside", data.businessunitList);
        setServiceProviderData((prevData) => ({
          ...prevData,
          businessUnits: data.result || [],
        }));
      } else {
        console.error("Invalid business unit response format:", response);
      }
    } catch (error) {
      console.error("Error fetching business units:", error);
    }
  };

  const fetchModules = async () => {
    try {
      const { data } = await getModuleList();

      if (data.success) {
        // console.log("Inside", data.businessunitList);
        setServiceProviderData((prevData) => ({
          ...prevData,
          moduleOptions: data.result || [],
        }));
      } else {
        console.error("Invalid business unit response format:", response);
      }
    } catch (error) {
      console.error("Error fetching business units:", error);
    }
  };

  const fetchUserRoleOptions = async () => {
    try {
      const { data } = await serviceProviderUserRole();
      console.log("User Role Response:", data);
      if (data.success) {
        console.log('User Role Options:', data.result);
        setServiceProviderData((prevData) => ({
          ...prevData,
          userRoleOptions: data.result,
        }));
      } else {
        console.error("Invalid user role response format:", response);
      }
    } catch (error) {
      console.error("Error fetching user role options:", error);
    }
  };

  const fetchOrganisationOptions = async () => {
    try {
      const response = await serviceProviderOrganisation();

      if (response && response.data) {
        const { data } = response;

        setServiceProviderData((prevData) => ({
          ...prevData,
          organisationOptions: data.organizationList,
        }));
      } else {
        console.error("Invalid user role response format:", response);
      }
    } catch (error) {
      console.error("Error fetching user role options:", error);
    }
  };

  useEffect(() => {
    fetchOrganisationOptions();
    fetchUserRoleOptions();
    fetchModules();
    fetchBusinessUnits();
  }, []);

  const validate = () => {
    const keys = {};
    if (!existsAndLength(serviceProviderData.name)) {
      keys.name = 'Please check name.';
    }
    if (!existsAndLength(serviceProviderData.email) || !validateEmail(serviceProviderData.email)) {
      keys.email = 'Please check email.';
    }
    if (!validateMobile(serviceProviderData.phone || serviceProviderData.phone.length !== 10)) {
      keys.phone = 'Please check phone.';
    }
    if (!existsAndLength(serviceProviderData.password)) {
      keys.password = 'Please check password.';
    }
    if (serviceProviderData.userRole.length === 0) {
      keys.userRole = "Please fill User Role";
    }
    if (serviceProviderData.selectedBusinessUnits === null) {
      keys.businessUnits = "Please fill Business Unit";
    }
    if (Object.keys(keys).length) {
      showNotification({
        color: 'red',
        title: 'Service Record',
        message: 'Make sure all fields are filled properly.',
      });
      setServiceProviderData((data) => ({
        ...data,
        errors: keys,
      }));
      return false;
    }
    return true;
  };
  const registerNewUser = async () => {
    if (validate()) {
      setServiceProviderData((data) => ({
        ...data,
        loading: loadingStates.LOADING,
        errors: {},
      }));
      const payload = {
        name: serviceProviderData.name,
        email: serviceProviderData.email,
        phone: serviceProviderData.phone,
        password: serviceProviderData.password,
        userRole: serviceProviderData.userRole,
        mapOrganization: serviceProviderData.selectedBusinessUnits.organization.id + "",
        mapBusinessunit: serviceProviderData.selectedBusinessUnits.id + "",
        hasAccessTo: serviceProviderData.selectedModules,
        enabled: serviceProviderData.enabled === 'enabled',
      };
      const resp = await apiWrapWithErrorWithData(registerNewUserApi(payload));
      if (resp?.success) {
        setServiceProviderData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
        showNotification({
          color: 'green',
          title: 'Service Provider Record Created.',
          message: 'Service Provider has been created.',
        });
        if (formClose) {
          formClose();
        }
      } else {
        showNotification({
          color: 'red',
          title: 'Service Provider Record',
          message: resp?.message || 'Service Provider Record could not be created.',
        });
        setServiceProviderData((prevState) => ({
          ...prevState,
          loading: loadingStates.NO_ACTIVE_REQUEST,
        }));
      }
    }
  };
  // const serviceHandler = async () => {
  //   if (validate()) {
  //     setServiceProviderData((data) => ({
  //       ...data,
  //       loading: loadingStates.LOADING,
  //       errors: {},
  //     }));
  //     const serviceProviderApiData = {
  //       name: serviceProviderData.name,
  //       email: serviceProviderData.email,
  //       phone: serviceProviderData.phone,
  //       password: serviceProviderData.password,
  //       userRole: serviceProviderData.userRole,
  //       mapOrganization: serviceProviderData.selectedBusinessUnits.organization.id + "",
  //       mapBusinessunit: serviceProviderData.selectedBusinessUnits.id + "",
  //       hasAccessTo: 'recovery',
  //       enabled: serviceProviderData.enabled === 'enabled',
  //     };
  //     const resp = await apiWrapWithErrorWithData(createServiceProvider({
  //       serviceProviderData: serviceProviderApiData,
  //     }));
  //     if (resp?.success) {
  //       setServiceProviderData((prevState) => ({
  //         ...prevState,
  //         loading: loadingStates.NO_ACTIVE_REQUEST,
  //       }));
  //       showNotification({
  //         color: 'green',
  //         title: 'Service Provider Record Created.',
  //         message: 'Service Provider has been created.',
  //       });
  //       if (formClose) {
  //         formClose();
  //       }
  //     } else {
  //       showNotification({
  //         color: 'red',
  //         title: 'Service Provider Record',
  //         message: resp?.message || 'Service Provider Record could not be created.',
  //       });
  //       setServiceProviderData((prevState) => ({
  //         ...prevState,
  //         loading: loadingStates.NO_ACTIVE_REQUEST,
  //       }));
  //     }
  //   }
  // };
  const roleTypeOptions = serviceProviderData.userRoleOptions.map((option) => ({
    label: option.name,
    value: option.id,
  }));
  const businessUnitOptions = serviceProviderData.businessUnits.map((option, index) => ({
    label: option.name,
    value: option,
  })
  );
  return (
    <div className="flex flex-col px-4 pb-8">
      <div className={styles.title}>Add New User</div>
      {/* <div className={`${styles.text} mt-1`}>
        Commodo eget a et dignissim dignissim morbi vitae, mi. Mi aliquam sit ultrices enim.
      </div> */}
      <div className=" grid grid-cols-3 gap-x-4 mt-8">
        <TextInput
          placeholder="Enter Name"
          label="Name"
          value={serviceProviderData.name}
          onChange={changeHandler('name')}
          error={serviceProviderData.errors.name}
          required
        />
        <TextInput
          placeholder="Enter 10 digit phone no."
          label="Phone"
          value={serviceProviderData.phone}
          onChange={changeHandler('phone')}
          error={serviceProviderData.errors.phone}
          required
        />
        <TextInput
          // className="mt-8"
          placeholder="Enter Email"
          label="Email"
          value={serviceProviderData.email}
          onChange={changeHandler('email')}
          error={serviceProviderData.errors.email}
          required
        />
        <TextInput
          className="mt-8"
          placeholder="Enter Password"
          label="Password"
          type="password"
          autoComplete='new-password'
          value={serviceProviderData.password}
          onChange={changeHandler('password')}
          error={serviceProviderData.errors.password}
          required
        />
        {/* <Select
          className="mt-8"
          placeholder="Enter Designation"
          label="Designation"
          data={userDetails?.designation === 'Admin' ? [
            { value: 'Lawyer', label: 'Lawyer' },
            { value: 'Clerk', label: 'Clerk' },
            { value: 'Researcher', label: 'Researcher' },
            { value: 'Admin', label: 'Admin' },
          ] : [
            { value: 'Lawyer', label: 'Lawyer' },
            { value: 'Clerk', label: 'Clerk' },
            { value: 'Researcher', label: 'Researcher' },
          ]
          }
          value={serviceProviderData.designation}
          onChange={changeHandler('designation')}
        // error={serviceProviderData.errors.designation}
        /> */}
        <MultiSelect
          className="mt-8"
          placeholder="select module"
          label="module"
          data={serviceProviderData.moduleOptions.map((option) => ({
            label: option.name,
            value: option.id,
          }))}
          // value={serviceProviderData.selectedModules}
          onChange={(val) => {
            setServiceProviderData((data) => ({
              ...data,
              selectedModules: val,
            }));
          }}
          required
        />
        <Select
          className="mt-8"
          placeholder="Business Unit/Location"
          label="Business Unit/Location"
          data={businessUnitOptions}
          onChange={(selectedBU) => {
            setServiceProviderData((data) => ({
              ...data,
              selectedBusinessUnits: selectedBU,
            }));
          }}
          error={serviceProviderData.errors.businessUnits}
          required
        />
        <MultiSelect
          label="User Role"
          className="mt-8"
          placeholder="Select user role"
          value={serviceProviderData.userRole}
          onChange={(val) => {
            console.log(val);
            setServiceProviderData((data) => ({
              ...data,
              userRole: val,
            }));
          }}
          data={serviceProviderData.userRoleOptions.map((option) => ({
            label: `${option.name} - ${option.moduleName}`,
            value: option.id,
          }))}
          error={serviceProviderData.errors.userRole}
          required
        />


        <Radio.Group
          className="mt-8"
          label="User Permission"
          // description="This is a description"
          spacing="xs"
          withAsterisk
          value={serviceProviderData.enabled}
          onChange={changeHandler('enabled')}
        >
          <Radio value="enabled" label="Enabled" />
          <Radio value="disabled" label="Disabled" />
        </Radio.Group>
      </div>



      <div className="flex justify-end">
        <Button
          onClick={registerNewUser}
          // onClick={serviceHandler}
          disabled={serviceProviderData.loading === loadingStates.LOADING}
          style={{
            backgroundColor: '#46BDE1',
            borderRadius: '0.5rem',
            color: '#F5F5F5',
          }}
        >
          {serviceProviderData.loading === loadingStates.LOADING
            && <BeatLoader color={colors.primary} size={10} />}
          {serviceProviderData.loading === loadingStates.NO_ACTIVE_REQUEST
            && <span> Save</span>}
        </Button>
      </div>
    </div>
  );
}

export default ServiceProviderForm;
