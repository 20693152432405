import React, { useState } from 'react';
import {
  AppShell, Header, Navbar, ActionIcon, ScrollArea
} from '@mantine/core';
import {
  Home, Paperclip,
  Bell, Template,
  LayoutSidebarLeftCollapse, LayoutSidebarRightCollapse, Snowman, ReportMoney, Plus, LetterCase, Mailbox
} from 'tabler-icons-react';
import { LinksGroup } from '../../components/NavbarLinkGroup';
import AppHeader from '../../components/Header/AppHeader';
import { useSelector } from "react-redux";
import { selectUserData } from "../../redux/selectors";
import { set } from 'date-fns';

const MainApp = ({ appContent }) => {

  const currentUser = useSelector(selectUserData) || {};
  const { name } = currentUser.loginAs;

  const mockdata = [
    {
      label: 'dashboard',
      icon: Home,
      link: '/app',
    },
    {
      label: 'notifications',
      icon: Bell,
      link: '/app/notifications',
    },
    {
      label: 'recovery locker',
      icon: Paperclip,
      link: '/app/recovery/locker',
    },
    // {
    //   label: 'case',
    //   icon: LetterCase,
    //   link: '/app/dispute-manager/cases'
    // },
    // {
    //   label: 'budget manager',
    //   icon: ReportMoney,
    //   link: '/app/budget-manager',
    // },
    // {
    //   label: 'gmail inbox',
    //   icon: Mailbox,
    //   link: '/app/gmail-inbox',
    // },
    // {
    //   label: 'Documents',
    //   icon: Mailbox,
    //   link: '/app/documents',
    // }
  ];

  if (['Initiator', 'Editor'].includes(name)) {
    mockdata.splice(2, 0, {
      label: 'my recovery',
      icon: Paperclip,
      link: '/app/recovery',
    });
  }
  if (['Initiator', 'Editor'].includes(name)) {
    mockdata.splice(3, 0, {
      label: 'new recovery',
      icon: Plus,
      link: '/app/recovery/new',
    });
  }
  if (name === 'Editor') {
    mockdata.splice(4, 0, {
      label: 'templates',
      icon: Template,
      initiallyOpened: true,
      links: [
        { label: 'notice', link: '/app/template/notice' },
        { label: 'email', link: '/app/template/email' },
      ],
    });
  } else if (name === 'Initiator') {
    mockdata.splice(4, 0, {
      label: 'templates',
      icon: Template,
      initiallyOpened: true,
      links: [
        { label: 'email', link: '/app/templates/email' },
      ],
    });
  }
  if (name === 'system-admin') {
    mockdata.splice(3, 0, {
      label: 'team manager',
      icon: Snowman,
      link: '/app/service-providers',
    });
  }


  const links = mockdata.map((item) => <LinksGroup {...item} key={item.label} />);

  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <AppShell
      padding="md"
      navbar={(
        <Navbar
          style={{ backgroundColor: '#F1F4FA' }}
          width={{ base: sidebarCollapsed ? 50 : 300 }}
          height="calc(100vh - 65px)"
          p="xs"
        >
          <Navbar.Section grow component={ScrollArea}>
            <div className="flex flex-col">
              <div className="flex justify-end">
                <ActionIcon color="blue" onClick={() => setSidebarCollapsed(!sidebarCollapsed)}>
                  {sidebarCollapsed
                    ? <LayoutSidebarRightCollapse />
                    : <LayoutSidebarLeftCollapse />}
                </ActionIcon>
              </div>
              {!sidebarCollapsed && <div>{links}</div>}
            </div>
          </Navbar.Section>
        </Navbar>
      )}
      header={<Header height={65}><AppHeader /></Header>}
    >
      <div style={{ backgroundColor: '#FFFFFF', minHeight: 'calc(100vh - 100px)' }}>
        {appContent}
      </div>
    </AppShell>
  );
};

export default MainApp;
