import React, { useState, useEffect } from "react";
import {
    Table,
    ActionIcon,
    Text,
    Select,
    TextInput,
    Button,
    Pagination,
    RangeSlider
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Download, Search, SortAscending } from "tabler-icons-react";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getRecoveryDashboardTable } from "../../utilities/apis/recovery";
import DashboardTableComponent from "./DashboardTableComponent";

const DashboardTableDisplay = () => {
    const [formState, setFormState] = useState({
        loading: false,
        tableList: [],
        filter: {
            status: "All",
            searchText: "",
            sortType: "DESC",
            orderByColumn: "",
            minAmount: 0,
            maxAmount: 0
        },
        pageNo: 1,
        pageSize: '10',
        totalDataCount: 0,
        apiCall: true,
    });
    const navigate = useNavigate();
    const fetchDetails = async () => {
        const payload = {
            filter: formState.filter,
            pageNo: formState.pageNo,
            pageSize: formState.pageSize
        };
        let response = await apiWrapWithErrorWithData(
            getRecoveryDashboardTable(payload)
        );
        if (response.success && response?.result) {
            response = response.result;
            console.log("response dashboard table -------->>", response);
            setFormState((prevState) => ({
                ...prevState,
                tableList: response.data.recoveryList,
                totalDataCount:
                    response.data.recoveryList.length > 0
                        ? response.data.recoveryList[0].totalDataCount
                        : 0,
            }));
        }
    };
    useEffect(() => {
        if (formState.apiCall) {
            fetchDetails();
            setFormState((prevState) => ({
                ...prevState,
                apiCall: false,
            }));
        }
    }, [formState.apiCall]);
    const handleClearClick = async () => {
        console.log("clear clicked", formState);
        setFormState({
            ...formState,
            filter: {
                status: "All",
                searchText: "",
                sortType: "DESC",
                orderByColumn: "",
                minAmount: 0,
                maxAmount: 0
            },
            pageNo: 1,
            pageSize: '10',
            apiCall: true,
        });
    };

    return (
        <>
            <br />
            <br />
            <br />
            <div className="flex w-full justify-between items-center">
                <div className="flex flex-col">
                    <div className="flex flex-row-sm">
                        <div className="flex items-center">
                            <Select
                                className="mx-2 min-w-[225px]"
                                placeholder="Select recovery status"
                                data={[
                                    "All",
                                    "Active",
                                    "Pending",
                                    "Write off",
                                    "Settlement",
                                    "Case creation",
                                    "Fully Recovered",
                                ]}
                                value={formState.filter.status}
                                onChange={(value) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            status: value,
                                        },
                                    });
                                }}
                            />
                            <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Search text"
                                icon={<Search size={14} />}
                                value={formState.filter.searchText}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            searchText: e.currentTarget.value,
                                        },
                                    });
                                }}
                            />
                            {/* <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Less than amount"
                                type="number"
                                value={formState.filter.lessThanAmount}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            lessThanAmount: e.currentTarget.value,
                                        },
                                    });
                                }}
                            />
                            <TextInput
                                className="mx-2 min-w-[225px]"
                                placeholder="Greater than amount"
                                type="number"
                                value={formState.filter.greaterThanAmount}
                                onChange={(e) => {
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            greaterThanAmount: e.currentTarget.value,
                                        },
                                    });
                                }}
                            /> */}
                            <RangeSlider
                                className="mx-2 min-w-[225px] mr-4"
                                labelAlwaysOn
                                min={0}
                                max={1000000}
                                step={100}
                                value={[formState.filter.minAmount, formState.filter.maxAmount]}
                                onChange={(value) => {
                                    console.log('this is value', value);
                                    setFormState({
                                        ...formState,
                                        filter: {
                                            ...formState.filter,
                                            minAmount: value[0],
                                            maxAmount: value[1],
                                        },
                                    });
                                }}
                                marks={[
                                    { value: 0, label: '0' },
                                    { value: 250000, label: '2.5L' },
                                    { value: 500000, label: '5L' },
                                    { value: 750000, label: '7.5L' },
                                    { value: 1000000, label: '10L' },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <Button
                className="mx-2"
                color="green"
                onClick={() =>
                    setFormState({
                        ...formState,
                        pageNo: 1,
                        apiCall: true,
                    })
                }
            >
                Search
            </Button>
            <Button className="mx-2" color="red" onClick={handleClearClick}>
                Clear
            </Button>
            <br />
            <br />
            <DashboardTableComponent
                tableData={formState.tableList}
                tableCount={formState.totalDataCount}
                setFormState={setFormState}
                formState={formState}
                from="dashboard"
            />
        </>
    );
};

export default DashboardTableDisplay;
